import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IState } from "store/types";
import { getAllMMS, getConfig } from "../actions/planCreationConfig";
import Spinner from "components/spinner";
import { PlanContainer } from "./PlanContainer";
import { Router } from "@reach/router";
import { CreatePlan } from "./CreatePlan";
import { SnackBar } from "./snackbar";
import { SubjectSearchModal } from "./search/SubjectSearchModal";
import { SubjectDetailsModal } from "./slotCard/SubjectDetailsModal";
import { AdminContainer } from "./admin/Admin";
import { Templates } from "./admin/Templates";
import { CreateTemplate } from "./admin/CreateTemplate";
import { featureToggles } from "../config/featureToggles";
import { Eligibility } from "./admin/Eligibility";
import { Helmet } from "react-helmet";
import { PlanAuthContainer } from "./PlanAuthForm";
import { DiscontinuedMMS } from "./admin/DiscontinuedMMS";
import { AccessMyCoursePlanner } from "./welcome/AccessMyCoursePlanner";
import { MyAccount } from "./account/MyAccount";
import { get } from "lib/fetch";
import { setProfile } from "actions/profile";

export const App = () => {
  const loading = useSelector((state: IState) => state.loading);
  const configLoaded = useSelector((state: IState) => !!state.planCreationConfig);
  const submittingChanges = useSelector((state: IState) => state.submittingChanges);
  const admin = useSelector((state: IState) => state.admin);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getConfig());
    if (featureToggles.componentEligibility) {
      dispatch(getAllMMS());
    }
    const isAuthenticated = async () => {
      if (featureToggles.authenticatedUser) {
        try {
          const resp = await get("/v1/profile");
          if (resp) {
            dispatch(setProfile(resp));
          }
        } catch (e) {
          // fail silently
        }
      }
    };
    isAuthenticated();
  }, [dispatch]);

  if (!!featureToggles.planAuth && admin && !admin.user) {
    return <PlanAuthContainer />;
  }

  return (
    <div>
      {submittingChanges && (
        <div
          // tabIndex={0} accessibility review: We also don’t need a tab-index because we are not expecting users to interact with the message
          aria-busy={true}
          role="alert"
          aria-label="Loading"
          className={submittingChanges ? "changes-in-progess-overlay" : ""}
        >
          <div className="loader" />
        </div>
      )}

      {!!featureToggles.siteCrawl && (
        // avoid site crawl for non-prod
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      )}

      <Spinner loading={loading} />
      {configLoaded && !loading && (
        // "primary" disables the router from scrolling you around - https://github.com/reach/router/issues/242
        <Router primary={false}>
          {featureToggles.authenticatedUser ? (
            <>
              <AccessMyCoursePlanner path="/" />
              <CreatePlan path="/new" />
              <MyAccount path="/account" />
            </>
          ) : (
            <CreatePlan path="/" />
          )}
          <>
            {/* Only to redirect */}
            <PlanContainer path="/plan/:id/*"></PlanContainer>
            <PlanContainer path="/:courseCode/:planYear/plan/:id">
              <SubjectSearchModal path="search/year/:year/semester/:semester/slot/:slotIndex" />
              <SubjectDetailsModal path="subject/:subjectRecordId" />
            </PlanContainer>
          </>
          <AdminContainer path="/staff">
            <Templates path="templates" />
            <CreateTemplate path="new-template" />
            <PlanContainer path="template/:id">
              <SubjectSearchModal path="search/year/:year/semester/:semester/slot/:slotIndex" />
              <SubjectDetailsModal path="subject/:subjectRecordId" />
            </PlanContainer>
            {featureToggles.componentEligibility && <Eligibility path="eligibility"></Eligibility>}
            {featureToggles.discontinuedMMS && <DiscontinuedMMS path="discontinued-mms"></DiscontinuedMMS>}
          </AdminContainer>
        </Router>
      )}
      <SnackBar />
    </div>
  );
};
