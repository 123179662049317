import store from "store";
import { getUserId } from "./userId";
import { clearProfile } from "actions/profile";

type METHOD_TYPE = "POST" | "PUT" | "GET" | "DELETE" | "PATCH";

type ErrorAction = (error: any) => void;

const onUnexpectedError: ErrorAction = (error) => console.log(error);

const apiUrlBase = "/apis";

export interface IFormValues {
  [key: string]: string | File | Blob;
}

const endpoint = (url: string) => {
  return `${apiUrlBase}${url}`;
};

async function baseExecFetch(
  url: string,
  method: METHOD_TYPE,
  process: (res: Response) => Promise<any>,
  body?: any,
  canError = false,
  _interactive = true,
): Promise<any> {
  if (body) {
    body = JSON.stringify(body);
  }

  let processedError = false;
  try {
    const endpointUrl = endpoint(url);
    const username = store.getState()?.admin?.user ?? "";
    const password = store.getState()?.admin?.password ?? "";
    const authUserId = store.getState()?.profile?.id ?? undefined;
    const res = await fetch(endpointUrl, {
      body,
      headers: {
        "content-type": "application/json",
        "X-User-ID": authUserId ? authUserId : getUserId(),
        "X-USER-NAME": username,
        "X-USER-PASS": password,
      },
      method,
    });

    if (!res.ok) {
      let payload;
      try {
        payload = await res.json();
      } catch (e) {
        //fail silently
      }

      // unauthorized access
      if (res.status === 401) {
        store.dispatch(clearProfile());
        throw { status: res.status, statusText: res.statusText, payload: { payload: { err: "Unauthorized" } } };
      }

      processedError = true;
      if (!canError) {
        onUnexpectedError({ res, payload });
      }

      // eslint-disable-next-line
      throw { status: res.status, statusText: res.statusText, payload };
    }

    if (res.status === 204) {
      return {};
    }

    return await process(res);
  } catch (err) {
    if (!canError && !processedError) {
      onUnexpectedError(err);
    }

    throw err;
  }
}

// Because DELETE often returns empty string body
const parseResponseBody = async (res: Response) => {
  const txt = await res.text();
  try {
    return JSON.parse(txt);
  } catch (e) {
    return txt;
  }
};

const execFetch = (url: string, method: METHOD_TYPE, body?: any, canError = false, interactive = true): Promise<any> =>
  baseExecFetch(url, method, parseResponseBody, body, canError, interactive);

export async function get(url: string, userId?: string, canError = false, interactive = true) {
  return execFetch(url, "GET", null, canError, interactive);
}

export async function post(url: string, body: any, canError = false) {
  return execFetch(url, "POST", body, canError);
}

export async function put(url: string, body: any, canError = false) {
  return execFetch(url, "PUT", body, canError);
}

export async function $delete(url: string, body: any = {}, canError = false) {
  return execFetch(url, "DELETE", body, canError);
}

export async function patch(url: string, body: any = {}, canError = false) {
  return execFetch(url, "PATCH", body, canError);
}

export const apiCall = (url: string, method: METHOD_TYPE = "GET", body?: any) => execFetch(url, method, body);

export const systemApiCall = (url: string, method: METHOD_TYPE = "GET", body?: any) =>
  baseExecFetch(url, method, (res) => res.json(), body, false, true);

export type ProgressFunction = (pe: ProgressEvent) => any;

export function submitForm(url: string, fields: IFormValues, headers: any, onprogress: ProgressFunction | null) {
  // FormData represents the payload of the form
  const form = new FormData();

  // Attach all files and field values
  Object.keys(fields).forEach((f) => form.append(f, fields[f]));

  // The XHR request/call
  const xhrRequest = new XMLHttpRequest();

  const endpointUrl = endpoint(url);

  // The "true" parametes makes the request asynchronous
  xhrRequest.open("POST", endpointUrl, true);

  // Set up the the request headers.
  // Skip "content-type" - it messes up xhr. See - http://www.olioapps.com/blog/formdata-fetch-gotchas/
  Object.keys(headers).forEach((h) => h.toLowerCase() !== "content-type" && xhrRequest.setRequestHeader(h, headers[h]));

  // Set up the callback for the upload progress
  xhrRequest.upload.onprogress = onprogress || (() => null);

  // Wrap the result callback in a promise
  const responsePromise = new Promise((resolve, reject) => {
    xhrRequest.onreadystatechange = () => {
      // State 4 means "Done" - https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/readyState
      if (xhrRequest.readyState === 4) {
        if (xhrRequest.status >= 200 && xhrRequest.status <= 299) {
          // Try to parse it if JSON
          const txt = xhrRequest.responseText;
          try {
            resolve(JSON.parse(txt));
          } catch (e) {
            resolve(txt);
          }
        } else {
          reject(xhrRequest.statusText);
        }
      }
    };
  });

  // Kick off the actual form submission
  xhrRequest.send(form);

  // Return the promise so the caller can await
  return responsePromise;
}
